export const environment = {
  production: false,
  apiProjectUrl: "https://a314-projects-qa.drh.avwaveinteractive.com/projects",
  apiUrlAdmin:
    "https://a314-admin-users-qa.drh.avwaveinteractive.com/admin-users",
  apiGoogleStorage:
    "https://a314-google-storage-qa.drh.avwaveinteractive.com/storage",
  urlStorage: "https://storage.googleapis.com/drh-avwave-qa/",
  testUser: {
    // tslint:disable-line
    token: "cualquiercosa",
    email: "user@user.user",
  },
};
